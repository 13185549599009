<template>
  <div class="about">
    <div
      class="beijingtupian2"
      :style="{ background: 'url('+imageList+')' }"
    >
    </div>
          <div class="waitao">
        <img
          src="../assets/wenzi3.svg"
          style="
            width: 537px;
            height: 28px;
            top: 101px;
            position: absolute;
          "
        /><img
          src="../assets/wenzi4.svg"
          style="
            width: 537px;
            height: 17px;
            top: 170px;
            position: absolute;
          "
        />
      </div>
    <div class="middle5" id="aaa">
      <img src="../assets/shjficon.svg" style="position: absolute; top: 80px" />
      <h6 class="title23">珊瑚积分平台</h6>
      <div class="line5"></div>
      <p class="intro37">
        积分模式，是一套以数据、资源、服务及技术位核心要素、重度依赖运营的，用户运营成长体系。用户拥有积分后，商业化行为明显提升，用户Arpu显著高于大盘其他功能点。我们提供各类积分体系解决方案；从账号管理到用户运营，以及运营中的商业化等一系列服务。
      </p>
      <img
        src="../assets/gxhyicon.svg"
        style="position: absolute; top: 260px; left: 460px"
      />
      <p class="intro38">高效活跃数据</p>
      <img
        src="../assets/dyyyicon.svg"
        style="position: absolute; top: 260px; left: 632px"
      />
      <p class="intro39" id="bbb">多样运营玩法</p>
      <img
        src="../assets/qdaqicon.svg"
        style="position: absolute; top: 260px; left: 804px"
      />
      <p class="intro40">强大安全保障</p>
      <h6 class="title24">融合DSP平台</h6>
      <div class="line6"></div>
      <img
        src="../assets/rhDSPicon.svg"
        style="position: absolute; top: 386px; left: 780px"
      />
      <p class="intro41">
        TIANZ
        DSP平台依托核心智能推荐技术，与国内大量中小媒体达成战略合作，整合安卓端和IOS端优质流量资源。平台充分利用大数据分析技术、广告投放智能化技术以及广告投放策略优化技术，对目标受众的年龄、性别、地域、兴趣、人群、行为习惯等多个维度进行筛选及定位，精准、高效地保障广告转换率，实现最佳的广告传播效果。
      </p>
      <img
        src="../assets/zywjicon.svg"
        style="position: absolute; top: 592px"
      />
      <p class="intro42">专业挖掘分析</p>
      <img
        src="../assets/zqyhicon.svg"
        style="position: absolute; top: 592px; left: 172px"
      />
      <p class="intro43">准确用户画像</p>
      <img
        src="../assets/jztficon.svg"
        style="position: absolute; top: 592px; left: 344px"
      />
      <p class="intro44">精准投放模型</p>
      <div class="maodian" id="ccc"></div>
    </div>
    <div style="width: 100%; background: #f7faff">
      <div class="middle6">
        <h6 class="title3">CORE MEDIA RESUURCES</h6>
        <h6 class="title2">核心媒体资源</h6>
        <div class="spzu">
          <!-- <div class="shipin"  v-for="(item,index) in  list" :key="index">
          </div> -->
          <div>
            <div class="shipin">
              <div class="ct1">
                <img
                   :src="coreResource[0].iocn"
                  style="position: absolute; top: 183px; left: 165px"
                />
                <div class="line7"></div>
                <p class="intro45" v-html="coreResource[0].name">{{coreResource[0].name}}</p>
              </div>
              <div class="xt1">
                <img
                  src="../assets/txaqicon.svg"
                  style="position: absolute; top: 126px; left: 165px"
                />
                <p class="intro46">腾讯安全工具APP</p>
                <div class="line8"></div>
                <span class="intro47"
                  >腾讯安全工具包括腾讯手机管家、腾讯WiFi管家、QQ同步助手三款超级APP媒体资源。我司连续四年成为腾讯文化传媒授权的核心代理商。</span
                >
              </div>
            </div>
          </div>
          <div>
            <div class="shipin">
              <div class="ct2">
                <img
                  :src="coreResource[1].iocn"
                  style="position: absolute; top: 183px; left: 165px"
                />
                <div class="line7"></div>
                <p class="intro45" v-html="coreResource[1].name">{{coreResource[1].name}}</p>
              </div>
              <div class="xt2">
                <img
                  src="../assets/mgicon.svg"
                  style="position: absolute; top: 113px; left: 165px"
                />
                <p class="intro48">中国移动咪咕广告平台</p>
                <div class="line9"></div>
                <span class="intro49"
                  >最具运营商特色的全媒体平台。咪咕依托中国移动8.37亿真实用户独家大数据，服务用户超过5亿，月活用户超过2.5亿；丰富的APP产品涵盖多种泛娱乐场景。我司连续3年成为咪咕广告平台授权的核心代理。</span
                >
              </div>
            </div>
          </div>
          <div>
            <div class="shipin">
              <div class="ct3">
                <img
                  :src="coreResource[2].iocn"
                  style="position: absolute; top: 183px; left: 165px"
                />
                <div class="line7"></div>
                <p class="intro45" v-html="coreResource[2].name">{{coreResource[2].name}}</p>
              </div>
              <div class="xt3">
                <img
                  src="../assets/jrtticon.svg"
                  style="position: absolute; top: 74px; left: 165px"
                />
                <p class="intro50">今日头条广告平台</p>
                <div class="line10"></div>
                <span class="intro51"
                  >字节跳动是最早把人工智能技术大规模应用于信息分发的公司之一。现全面引领移动互联网发展趋势，具有广大的人群基础。今日头条是字节跳动的核心产品，长期占据APP
                  STORE新闻类榜首。迄今为止，头条结合不同的维度，不断扩充产品矩阵，串联用户触网场景。我司为巨量引擎广告平台全国全行业代理商。</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tag" id="ddd"></div>
   <!--  <div class="middle7" v-for="(item, index) in list" :key="index">
      <img :src="item.img" style="width: 480px; height: 468px" />
      <h6 class="title4" >{{ item.title1 }}</h6>
      <h6 class="title5">{{ item.title2 }}</h6>
      <p class="intro52">{{ item.descrite1 }}</p>
      <p class="intro53">{{ item.descrite2 }}</p>
    </div>>-->
    <div class="middle7">
        <img :src="crossBorderImg" style="width:480px;height:468px">
        <h6 class="title4">COMPANY PROFILE</h6>
        <h6 class="title5" v-html="crossBorderTitle">{{crossBorderTitle}}</h6>
        <p class="intro52" v-html="crossBorderOneParagraph">{{crossBorderOneParagraph}}</p>
        <p class="intro53" v-html="crossBorderTwoParagraph">{{crossBorderTwoParagraph}}</p>
      </div>
    <div class="middle8">
      <h6 class="title1">SUCCESS STORUES</h6>
      <h6 class="title2">精选成功案例</h6>
      <div class="shipinzu">
        <div>
          <video-player
            v-if="a"
            class="shipin0 vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
          >
          </video-player>
        </div>
        <div>
          <video-player
            class="shipin0 vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions2"
          >
          </video-player>
        </div>
        <div>
          <video-player
            class="shipin0 vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions3"
          >
          </video-player>
        </div>
      </div>
    </div>
    <div class="lanbian"></div>
    <!-- 底部内容 -->
    <vbottom></vbottom>
  </div>
</template>
<script>  
import {businessBanner} from "@/api/index" ;
import vbottom from "../components/bottom";
  export default {
    data () {
      return {
        a:true,
       // list:[{
    //    img:require('../assets/kjdspt@2X.png'),
    //    title1:'COMPANY PROFILE',
    //    title2:'跨境电商',
    //    descrite1:'依托北京总公司在营销上的优势和广州子公司的供应链资源，公司组建了跨境电商事业部，我们是阿里SKA客户，营销资源充足，经营范围涵盖服装类、家居类、3C数码类、电动车等多种类产品，业务辐射整个欧美地区，并且正在向其他地区持续扩展。',
    //    descrite2:'作为计划B2B，B2C双向并行的部门，我们秉持为消费者服务，抓住细节，赢在细节的理念，满足消费者的多样化需求，专注服务海外市场，通过充分且多维度的市场调研，结合强大的供应链和营销能力，打造爆款。希望用数字化的方式连接全球市场和中国优质供应链，打造数字化时代的全球化品牌。致力于将中国制造，中国创造带向世界。'
 //     }], 
       imageList: "",
       coreResource: [
         {
            iocn: "",
            name: "",
          },
          {
            iocn: "",
            name: "",
          },
          {
            iocn: "",
            name: "",
          },
       ],
       crossBorderTitle:"",
       crossBorderImg:"",
       crossBorderOneParagraph:"",
       crossBorderTwoParagraph:"",
        playerOptions: {
                    //播放速度
                    playbackRates: [0.5, 1.0, 1.5, 2.0], 
                    //如果true,浏览器准备好时开始回放。
                    autoplay: false, 
                    // 默认情况下将会消除任何音频。
                    muted: false, 
                    // 导致视频一结束就重新开始。
                    loop: false, 
                    // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    preload: 'auto', 
                    language: 'zh-CN',
                     // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    fluid: true,
                    sources: [{
                        //类型
                        type: "video/mp4",
                        //url地址
                        src: require("../assets/v1_20200302112034.mp4")
                    },],
                    //你的封面地址
                    poster: require("../assets/anli1@2X.png"), 
                     //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    notSupportedMessage: '此视频暂无法播放，请稍后再试',
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        //全屏按钮
                        fullscreenToggle: false 
                    }
                },
                playerOptions2: {
                    //播放速度
                    playbackRates: [0.5, 1.0, 1.5, 2.0], 
                    //如果true,浏览器准备好时开始回放。
                    autoplay: false, 
                    // 默认情况下将会消除任何音频。
                    muted: false, 
                    // 导致视频一结束就重新开始。
                    loop: false, 
                    // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    preload: 'auto', 
                    language: 'zh-CN',
                     // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    fluid: true,
                    sources: [{
                        //类型
                        type: "video/mp4",
                        //url地址
                        src: require("../assets/v2_20200302112359.mp4")
                    }],
                    //你的封面地址
                    poster: require("../assets/anli2@2X.png"), 
                     //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    notSupportedMessage: '此视频暂无法播放，请稍后再试',
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        //全屏按钮
                        fullscreenToggle: false 
                    }
                },
                playerOptions3: {
                    //播放速度
                    playbackRates: [0.5, 1.0, 1.5, 2.0], 
                    //如果true,浏览器准备好时开始回放。
                    autoplay: false, 
                    // 默认情况下将会消除任何音频。
                    muted: false, 
                    // 导致视频一结束就重新开始。
                    loop: false, 
                    // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    preload: 'auto', 
                    language: 'zh-CN',
                     // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    fluid: true,
                    sources: [{
                        //类型
                        type: "video/mp4",
                        //url地址
                        src: require("../assets/v3_20200302112602.mp4")
                    }],
                    //你的封面地址
                    poster: require("../assets/anli3@2X.png"), 
                     //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    notSupportedMessage: '此视频暂无法播放，请稍后再试',
                    controlBar: {
                        timeDivider: true,
                        durationDisplay: true,
                        remainingTimeDisplay: false,
                        //全屏按钮
                        fullscreenToggle: false 
                    }
                }
                
    }
  },
  components: { vbottom },
  mounted() {
    if (window.location.hash) {
      this.goAnchor(window.location.hash)
    }
    this.getBusinessBanner()
  },

   methods:{
   goAnchor(selector) {
     console.log(selector);
      setTimeout(() => {
       let arr = selector.split("#")
       const anchor = document.getElementById(arr[arr.length-1])
       anchor.scrollIntoView()
      }, 10)
    },
    getBusinessBanner() {
      businessBanner().then((data) => {
        this.imageList = JSON.parse(data.imageList)[0];
        this.coreResource[0].iocn = data.coreResource[0].iocn;
        this.coreResource[1].iocn = data.coreResource[1].iocn;
        this.coreResource[2].iocn = data.coreResource[2].iocn;
        this.coreResource[0].name = data.coreResource[0].name;
        this.coreResource[1].name = data.coreResource[1].name;
        this.coreResource[2].name = data.coreResource[2].name;
        this.crossBorderTitle = data.crossBorderTitle;
        this.crossBorderImg = data.crossBorderImg;
        this.crossBorderOneParagraph = data.crossBorderOneParagraph;
        this.crossBorderTwoParagraph = data.crossBorderTwoParagraph;
        this.playerOptions['sources'][0]['src'] = data.videoList[0].videoUrl;
        this.playerOptions2['sources'][0]['src'] = data.videoList[1].videoUrl;
        // this.playerOptions3['sources'][0]['src'] = data.videoList[2].videoUrl;
      });
    },
   }
  }
</script>
<style scoped>
.title1 {
  width: 1180px;
  height: 14px;
  font-size: 14px;
  font-weight: 300;
  color: #868e94;
  line-height: 14px;
  margin-top: 80px;
  text-align: center;
}
.title2 {
  width: 1180px;
  height: 30px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
  margin-top: 10px;
  text-align: center;
}
.title3 {
  width: 1180px;
  height: 14px;
  font-size: 14px;
  font-weight: 300;
  color: #868e94;
  line-height: 14px;
  padding-top: 60px;
  text-align: center;
}
.title4 {
  width: 134px;
  height: 14px;
  font-size: 14px;
  font-weight: 300;
  color: #868e94;
  line-height: 14px;
  position: absolute;
  top: 60px;
  left: 540px;
}
.title5 {
  width: 120px;
  height: 30px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
  position: absolute;
  top: 84px;
  left: 540px;
}
.waitao {
  position: relative;
  width: 1180px;
  margin: auto;
  top:-288px;
  left:332px;
  margin:auto
}
.beijingtupian2 {
  /* background: url('../assets/banner2@2X.png'); */
  width: 100%;
  height: 288px;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  min-width: 1440px;
}
.middle5 {
  width: 1180px;
  height: 738px;
  margin: auto;
  position: relative;
}
.title23 {
  width: 108px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: absolute;
  top: 110px;
  left: 460px;
}
.line5 {
  width: 40px;
  height: 4px;
  background: #005fff;
  border-radius: 2px;
  position: absolute;
  top: 138px;
  left: 460px;
}
.intro37 {
  width: 720px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top: 162px;
  left: 460px;
}
.intro38 {
  width: 96px;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  position: absolute;
  top: 270px;
  left: 506px;
}
.intro39 {
  width: 96px;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  position: absolute;
  top: 270px;
  left: 678px;
}
.intro40 {
  width: 96px;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  position: absolute;
  top: 270px;
  left: 850px;
}
.title24 {
  width: 110px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: absolute;
  top: 416px;
}
.line6 {
  width: 40px;
  height: 4px;
  background: #005fff;
  border-radius: 2px;
  position: absolute;
  top: 444px;
}
.intro41 {
  width: 720px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top: 468px;
}
.intro42 {
  width: 96px;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  position: absolute;
  top: 602px;
  left: 46px;
}
.intro43 {
  width: 96px;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  position: absolute;
  top: 602px;
  left: 218px;
}
.intro44 {
  width: 96px;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  position: absolute;
  top: 602px;
  left: 390px;
}
.middle6 {
  width: 1180px;
  height: 678px;
  background: #f7faff;
  margin: 0 auto;
}
.spzu {
  margin-top: 40px;
  width: 1180px;
  height: 464x;
  display: flex;
  justify-content: space-around;
}
.shipin {
  width: 366px;
  height: 464px;
  float: left;
  margin: 0 auto;
  position: relative;
  border-radius: 4px 4px 4px 4px;
}
.line7 {
  width: 40px;
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  position: absolute;
  top: 239px;
  left: 163px;
}
.line8 {
  width: 40px;
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  position: absolute;
  top: 210px;
  left: 163px;
}
.line9 {
  width: 40px;
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  position: absolute;
  top: 197px;
  left: 163px;
}
.line10 {
  width: 40px;
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  position: absolute;
  top: 158px;
  left: 163px;
}
.intro45 {
  width: 366px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  position: absolute;
  top: 263px;
  text-align: center;
}
.intro46 {
  width: 366px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  position: absolute;
  top: 182px;
  text-align: center;
}
.intro47 {
  width: 306px;
  height: 104px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
  position: absolute;
  top: 234px;
  left: 30px;
  text-align: justify;
  word-break: break-all;
}
.intro48 {
  width: 366px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  position: absolute;
  top: 169px;
  text-align: center;
}
.intro49 {
  width: 306px;
  height: 130px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
  position: absolute;
  top: 221px;
  left: 30px;
  text-align: justify;
  word-break: break-all;
}
.intro50 {
  width: 366px;
  height: 18px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  position: absolute;
  top: 130px;
  text-align: center;
}
.intro51 {
  width: 306px;
  height: 208px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
  position: absolute;
  top: 182px;
  left: 30px;
  text-align: justify;
  word-break: break-all;
}
.spzu div:first-child .shipin {
  background-image: url(../assets/aqgjbj-ct@2X.png);
  background-size: cover;
}
.spzu > div:first-child:hover .shipin {
  background-image: url(../assets/aqgjbj-xt@2X.png);
}
.spzu > div:first-child:hover .xt1 {
  display: block;
}
.spzu > div:first-child:hover .ct1 {
  display: none;
}
.xt1 {
  display: none;
}
.xt2 {
  display: none;
}
.xt3 {
  display: none;
}
.spzu div:nth-child(2) .shipin {
  background-image: url(../assets/mg-ct.png);
  background-size: cover;
}
.spzu > div:nth-child(2):hover .shipin {
  background-image: url(../assets/jrtt-xt.png);
}
.spzu > div:nth-child(2):hover .xt2 {
  display: block;
}
.spzu > div:nth-child(2):hover .ct2 {
  display: none;
}
.spzu div:nth-child(3) .shipin {
  background-image: url(../assets/jrtt-ct@2X.png);
  background-size: cover;
}
.spzu > div:nth-child(3):hover .shipin {
  background-image: url(../assets/mg-xt.png);
}
.spzu > div:nth-child(3):hover .xt3 {
  display: block;
}
.spzu > div:nth-child(3):hover .ct3 {
  display: none;
}
.tag {
  height: 80px;
}
.middle7 {
  width: 1180px;
  height: 468px;
  margin: 0 auto;
  background-image: url(../assets/kjdsbj@2X.png);
  border-radius: 4px;
  position: relative;
  background-size: cover;
}
.intro52 {
  width: 580px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top: 154px;
  left: 540px;
}
.intro53 {
  width: 580px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top: 278px;
  left: 540px;
}
.middle8 {
  width: 1180px;
  height: 714px;
  margin: 0 auto;
}
.shipinzu {
  margin-top: 40px;
  width: 1180px;
  height: 540px;
  display: flex;
  justify-content: space-around;
}
.shipin0 {
  width: 304px;
  height: 540px;
  float: left;
  margin: 0 auto;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}
.shipinzu div:first-child .shipin0 {
  background-image: url(../assets/anli1@2X.png);
  background-size: cover;
}
.shipinzu div:nth-child(2) .shipin0 {
  background-image: url(../assets/anli2@2X.png);
  background-size: cover;
}
.shipinzu div:nth-child(3) .shipin0 {
  background-image: url(../assets/anli3@2X.png);
  background-size: cover;
}
.bianhua:hover {
  background: url(../assets/bfjxt.svg);
}
.lanbian {
  width: 100%;
  height: 300px;
  background: #f7faff;
  position: absolute;
  top: 2810px;
  z-index: -1;
}
.maodian {
  height: 1px;
  position: absolute;
  top: 672px;
}
</style>
